<template>
  <div>
    <div
      :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
      class="invoice-box"
    >
      <div>
        <div class="mb-1">
          {{ $t("settings.lang_userid") }}:
          {{ orderbonArchiveData.user_id }}
        </div>
        <div class="mb-1">
          <span class="text-capitalize">{{ $t("generic.lang_time") }}:</span>
          {{
            $moment.unix(orderbonArchiveData.timestamp).format("DD.MM.yyyy HH:mm:ss")
          }}
        </div>
        <div class="mb-1">
          {{ $t("generic.lang_TableNo") }}: {{ orderbonArchiveData.tableNo }}
        </div>
        <div class="mb-11">
          {{ $t("generic.lang_party") }}:
          {{ orderbonArchiveData.tableParty }}
        </div>
      </div>

      <b-table
        :fields="fields"
        :items="computedOrderbonBody"
        hover
        striped
        :table-variant="this.$vuetify.theme.dark ? 'dark' : ''"
      >
      </b-table>

      <div class="mt-12 d-flex align-center align-content-center">
        <v-select
          v-model="selectedPrinter"
          :items="printers"
          :label="$t('settings.lang_printer')"
          dense
          item-text="name"
          return-object
          outlined
        />

        <v-btn
          color="primary"
          large
          class="mb-8"
          :loading="loadingReprint"
          :disabled="this.loading || loadingReprint || !selectedPrinter"
          @click="reprintInvoice"
        >
          <i class="lnr-printer btn-icon-wrapper"> </i>
          <span class="ml-2 text-capitalize">
            {{ $t("generic.lang_reprint") }}
          </span>
        </v-btn>
      </div>
    </div>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard
        :accept="hideTouchKeyboard"
        :cancel="hideTouchKeyboard"
        :defaultKeySet="touchKeyboard.keySet"
        :input="touchKeyboard.input"
        :layout="touchKeyboard.layout"
        :options="touchKeyboard.options"
        class="internalWidth"
        id="onScreenKeyboard"
        v-if="touchKeyboard.visible"
      />
    </div>
  </div>
</template>

<style scoped>
.invoice-box {
  background-color: #ffffff;
  max-width: 1200px;
  margin: auto;
  padding: 30px;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  font-size: 16px;
  line-height: 24px;
  font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  color: #555;
}

.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}

.invoice-box table td {
  padding: 5px;
  vertical-align: top;
}

.invoice-box table tr td:nth-child(n + 2) {
  text-align: right;
}

.invoice-box table tr.top table td {
  padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
}

.invoice-box table tr.information table td {
  padding-bottom: 40px;
}

.invoice-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.item input {
  padding-left: 5px;
}

.invoice-box table tr.item td:first-child input {
  margin-left: -5px;
  width: 100%;
}

.invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
}

.invoice-box input[type="number"] {
  width: 60px;
}

@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .invoice-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
}

/** RTL **/
.rtl {
  direction: rtl;
  font-family: Tahoma, "Helvetica Neue", "Helvetica", Helvetica, Arial,
    sans-serif;
}

.rtl table {
  text-align: right;
}

.rtl table tr td:nth-child(2) {
  text-align: left;
}
</style>

<script>
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";
import mixin from "@/mixins/KeyboardMixIns";
import { mapState } from "vuex";
import { createOrderBonPrintingData } from "@/plugins/printing/printOrderBon";
import { printDataFromPrinter } from "@/plugins/printing/printerController";

export default {
  name: "DisplayOrderbonArchiveComponent",
  mixins: [mixin],

  data() {
    return {
      ENDPOINTS,
      loading: false,
      loadingReprint: false,
      orderbonArchiveData: {},
      items: [],
      selectedPrinter: null,
    };
  },

  computed: {
    ...mapState(["user"]),
    ...mapState("printer", {
      printers: (state) => state.printers,
    }),
    fields() {
      return [
        {
          key: "ean",
          sortable: false,
          label: this.$i18n.t("erp.lang_ware_create_id"),
        },
        {
          key: "name",
          sortable: false,
          label: this.$i18n.t("erp.lang_itemname"),
        },
        {
          key: "freeText",
          sortable: false,
          label: this.$i18n.t("generic.lang_Freitext"),
        },
        {
          key: "amount",
          sortable: false,
          label: this.$t("erp.lang_QTY"),
        },
        {
          key: "discount",
          sortable: false,
          label: this.$t("generic.lang_discount"),
        },
        {
          key: "originalSellPrice",
          sortable: false,
          label: this.$t("generic.lang_originalPrice"),
        },
        {
          key: "sellPrice",
          sortable: false,
          label: this.$t("erp.lang_sellPrice"),
        },
      ];
    },
    computedOrderbonBody() {
      let items = this._.cloneDeep(this.items);

      return items.map((item) => {
        item.originalSellPrice = this.$options.filters.currency(
          item.originalSellPrice
        );
        item.sellPrice = this.$options.filters.currency(item.sellPrice);
        return item;
      });
    },
  },

  mounted() {
    this.getData();
  },

  methods: {
    getData() {
      this.loading = true;

      this.axios
        .post(ENDPOINTS.SETTINGS.ORDERBON_ARCHIVE.GET, {
          orderbonArchiveUuid: this.$route.params.id,
        })
        .then((res) => {
          if (res.data.status) {
            this.orderbonArchiveData = res.data.data;
            this.items = this.orderbonArchiveData.orderbonBody;
          }
        })
        .catch(() => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred"),
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    reprintInvoice() {
      this.loadingReprint = true;

      let realUser = {
        userID: this.user.userID,
        name: this.user.name,
        last_name: this.user.last_name,
      };

      let data = {
        userID: this.orderbonArchiveData.user_id,
        timestamp: this.orderbonArchiveData.timestamp,
        table: ((this.orderbonArchiveData.tableNo !== null && this.orderbonArchiveData.tableNo != "0") ? this.orderbonArchiveData.tableNo : "Tresenverkauf"),
        party: this.orderbonArchiveData.tableParty,
        isAsap: false,
        isDelivery: false,
      };

      let printData = createOrderBonPrintingData(
        this.orderbonArchiveData.orderbonBody,
        data,
        this.selectedPrinter.options,
        this.orderbonArchiveData.freeText,
        realUser,
        false,
        false,
        true,
      );

      printDataFromPrinter(this.selectedPrinter, printData)
        .then(() => {})
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred"),
            color: "error",
          });
        });

      this.loadingReprint = false;
    },
  },
};
</script>
