<template>
  <div>
    <page-title
      :heading="$t('settings.lang_orderbonarchive')"
      :subheading="$t('settings.lang_orderbonarchive')"
      :icon="icon"
      showPreviousButton
    ></page-title>

    <div class="app-main__inner">
      <DisplayOrderbonArchiveComponent />
    </div>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle.vue";
import DisplayOrderbonArchiveComponent from "../../../components/settings/cashier/orderbonArchive/DisplayOrderbonArchiveComponent.vue";

export default {
  name: "DisplayOrderbonArchive",

  components: {
    PageTitle,
    DisplayOrderbonArchiveComponent,
  },

  data: () => ({
    icon: "pe-7s-search icon-gradient bg-tempting-azure",
  }),
};
</script>
